import React from 'react';

import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import styled, { css } from 'styled-components';


const EntryListItem = ({
  entry,
  isOnBlog
}) => {

  const postDate = new Date(entry.postDate);

  return (
    <Container isOnBlog={isOnBlog}>
      <EntryHeader>
        <LazyLoadImage 
          src={entry.featuredImage[0].url}
          alt={'Featured photo for article ' + entry.title}
          height={300}
        />
        <h3>{entry.title}</h3>
      </EntryHeader>
      <DetailsWrapper isOnBlog={isOnBlog}>
        <p className={'bebas'} >By {entry.writer}</p>
        {
          isOnBlog && (
            <p className={'bebas'}>{postDate.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' })}</p>
          )
        }
      </DetailsWrapper>
      <StyledLink isOnBlog={isOnBlog} to={'/quiet-please/' + entry.slug}>
        Read Article
      </StyledLink>
      <img src={'/icons/pin-flag.svg'} alt="Golf pin flag icon" />
    </Container>
  )
};

EntryListItem.propTypes = {

};

export default EntryListItem;

const Container = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2em;
  > img {
    width: 25px;
    height: auto;
    margin-top: 3em;
  }
  &:last-of-type {
    ${( { isOnBlog } ) =>
      isOnBlog &&
      css`
        > img {
          display: block
        }
      `
    };
  }
  ${( { isOnBlog } ) =>
    isOnBlog &&
    css`
      > img {
        display: block !important;
        order: 2;
        margin-top: 0;
      }
      &:nth-of-type(3n-1) {
        img {
          margin-top: 0;
        }
      }
    `
  };
  @media all and (min-width: 900px) {
    > img {
      display: none;
    }
    &:nth-of-type(3n-1) {
      > img {
        display: block;
        margin-top: 4em;
      }
    }
    ${( { isOnBlog } ) =>
    isOnBlog &&
      css`

        &:nth-of-type(3n-1) {
          > img {
            margin-top: 0;
          }
        }
      `
    };
  }
`;

const EntryHeader = styled.div`
  position: relative;
  width: 100%;
  &:after {
    content:"";
    background-color: rgba(0,0,0, 0.3);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
  }
  img {
    width: 100%;
    height: auto;
    display: block;
  }
  h3 {
    width: 100%;
    text-align: center;
    color: white;
    font-size: 3em;
    position: absolute;
    bottom: 15px;
    left: 0px;
    z-index: 3;
    padding: 0 2em;
  }
  @media all and (min-width: 600px) {
    img {
      min-height: 260px;
      max-height: 260px;
      width: 100%;
      object-fit: cover;
    }
  }
`;

const DetailsWrapper = styled.div`
  p {
    color: ${({ theme }) => theme.colors.Primary};
    font-size: 2.4em;
    letter-spacing: 1px;
    text-align: center;
    margin: 1em 0;
    ${( { isOnBlog } ) =>
    isOnBlog &&
      css`
        margin-bottom: 0;
      `
    };
    &:nth-of-type(2) {
      margin-top: 0em;
      color: ${({ theme }) => theme.colors.Orange};
      margin-bottom: 1em;
    }
  }
`;


const StyledLink = styled(Link)`
  font-family: 'Bebas Neue', serif;
  font-size: 2.4em;
  color: ${({ theme }) => theme.colors.Primary};
  border: 2px solid ${({ theme }) => theme.colors.Primary};
  align-self: center;
  padding: 0.2em 1em;
  margin-top: 0.5em;
  line-height: 1;
  transition: all 0.3s ease-in-out;
  ${( { isOnBlog } ) =>
      isOnBlog &&
      css`
        order: 3;
        margin-top: 1.25em;
      `
    };
  &:hover { 
    background-color: ${({ theme }) => theme.colors.Primary};
    color: white;
  }
`;

