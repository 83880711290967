/**
 *
 * IndexPage
 *
 *
 */

import React, { memo } from 'react';
import styled from 'styled-components';

import { graphql } from 'gatsby';

import ContentEngine from 'components/content/ContentEngine';
import SectionHeadline from 'components/elements/SectionHeadline';
import ReadOn from 'components/sections/QuietPlease';
import SubmitStory from 'components/blocks/SubmitStory';

const Blog = ({ data }) => {

  console.log(data);
  const entry = data.entry;
  const relatedEntries = data.relatedEntries['nodes'];

  const { contentEngine } = entry;

  const postDate = new Date(entry.postDate);

  return (
    <Container>
      <EntryHeader>
        <PageTitle greenText>
          Quiet Please
        </PageTitle>
        <TitleWrapper>
          <img src={entry.featuredImage && entry.featuredImage[0].url} alt=""/>
          <h1>{entry.title}</h1>
        </TitleWrapper>
      </EntryHeader>
      <DetailsWrapper>
        <p className={'bebas'}>By {entry.writer}</p>
        <p className={'bebas'}>{postDate.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' })}</p>
        <img src="/icons/pin-flag.svg" alt="Icon of golf pin"/>
      </DetailsWrapper>
      {/* Pass in all content blocks here */}
      <ContentEngine blocks={contentEngine} />
      <ReadOn isOnBlog={true} entries={relatedEntries}/>
      {/* <SubmitStory /> */}
    </Container>
  );
};

Blog.propTypes = {

};

export default memo(Blog);

const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  background-color: white;
  padding-bottom: 6em;
`;

const EntryHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  &:after {
    content:"";
    background-color: rgba(0,0,0, 0.3);
    width: 90%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 5%;
    z-index: 2;
  }
  img {
    width: 90%;
    height: auto;
    display: block;
    align-self: center;
  }
  h1 {
    color: white;
    font-size: 3em;
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    width: 90%;
    text-align: center;
  }
  @media all and (min-width: 600px) {
    img {
      max-height: 70vh;
      object-fit: cover;
      object-position: center center;
    }
    h1 {
      font-size: 5.6em;
      bottom: 50%;
      transform: translateX(-50%) translateY(50%);
    }
  }
`;

const DetailsWrapper = styled.div`
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3em;
  width: 100%;
  max-width: ${({ theme }) => theme.content.CONTENT_WIDTH};
  > img {
    width: 100%;
    height: auto;
    max-width: 25px;
    margin-top: 2em;
  }
  p {
    letter-spacing: 1px;
    text-align: center;
    font-size: 2.2em;
    &:first-of-type {
      color: ${({ theme }) => theme.colors.Primary};
    }
    &:last-of-type {
      color: ${({ theme }) => theme.colors.Orange};
    }
  }
  @media all and (min-width: 600px) {
    flex-direction: row;
    padding-bottom: 1.5em;
    margin-top: 5em;
    width: 90%;
    > img {
      display: none;
    }
    p:first-of-type {
      margin-right: 0.5em;
      &:after {
        content: "|";
        margin-left: 0.5em;
      }
    }
  }
`;

const PageTitle = styled(SectionHeadline)`
  margin: 2em 0;
  @media all and (min-width: 900px) {
    &:before,
    &:after {
      width: 50%;
      height: 2.5em;
    }
    &:before {
      top: 30%;
      left: 20%;
    }
    &:after {
      bottom: 30%;
      right: 10%;
    }
    h2 {
      font-size: 5em;
    }
  }
`;


export const query = graphql`
  query($id: ID) {
    entry: craftEntryInterface(remoteId: {eq:$id}) {
      ... on Craft_quietPlease_quietPlease_Entry {
        title
        writer
        postDate
        featuredImage {
          url
        }
        contentEngine {
          ... on Craft_contentEngine_richText_BlockType {
            typeHandle
            body
          }
          ... on Craft_contentEngine_blockQuote_BlockType {
            typeHandle
            citation
            quote
          }
          ... on Craft_contentEngine_videoEmbed_BlockType {
            typeHandle
            videoUrl
          }
          ... on Craft_contentEngine_headline_BlockType {
            typeHandle
            text
          }
          ... on Craft_contentEngine_lineBreak_BlockType {
            typeHandle
            icon {
              url
            }
          }
          ... on Craft_contentEngine_pullQuote_BlockType {
            typeHandle
            quote
          }
          ... on Craft_contentEngine_cta_BlockType {
            typeHandle
            textColor
            buttonColor
            buttonLink
            buttonText
            newTab
          }
          ... on Craft_contentEngine_photoGallery_BlockType {
            typeHandle
            slide {
              ... on Craft_slide_BlockType {
                id
                image {
                  url
                  focalPoint
                }
              }
            }
          }
          ... on Craft_contentEngine_imageOneUp_BlockType {
            typeHandle
            image {
              url
              focalPoint
            }
          }
          ... on Craft_contentEngine_imageTwoUp_BlockType {
            typeHandle
            images {
              ... on Craft_images_BlockType {
                id
                image {
                  url
                  focalPoint
                }
              }
            }
          }
        }
      }
    }
    relatedEntries: allCraftQuietPleaseQuietPleaseEntry(filter: {remoteId: {nin: ["not",$id]}}, limit: 3) {
      nodes {
        ... on Craft_quietPlease_quietPlease_Entry {
          slug
          title
          writer
          postDate
          featuredImage {
            url
          }
        }
      }
    }
  }
`;