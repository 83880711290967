import React, { memo } from 'react';

import styled, { css } from 'styled-components';

import { Link } from 'gatsby';

import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import SectionHeadline from 'components/elements/SectionHeadline';

import EntryListItem from 'components/blocks/EntryListItem';


const QuietPlease = ({
  entries,
  isOnBlog,
  isOnCarryOnPage
}) => {


  return (
    <Container isOnBlog={isOnBlog} isOnCarryOnPage={isOnCarryOnPage}>
      {
        isOnBlog
          ? <BasicHeadline><span>Read On</span></BasicHeadline>
          : <BasicHeadline><span>Read On</span></BasicHeadline>
      }
      {
        !isOnCarryOnPage
          ? <Headline greenText>Quiet Please</Headline>
          : ''
      }
      
      <EntryList isOnBlog={isOnBlog} isOnCarryOnPage={isOnCarryOnPage}>
        {
          entries.map((entry) => (
            <EntryListItem isOnBlog={isOnBlog} entry={entry} key={entry.title}/>
          ))
        }
      </EntryList>
      {
        !isOnBlog && !isOnCarryOnPage && (
          <CtaLink to="/quiet-please">View All Articles</CtaLink>
        )
      }
    </Container>
  )
};

QuietPlease.propTypes = {
  mapIsOpen: PropTypes.bool
};

export default QuietPlease;


const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding-top: 1em;
  padding-bottom: 4em;
  
  ${( { isOnBlog, isOnCarryOnPage } ) =>
    (isOnBlog || isOnCarryOnPage) &&
    css`
      max-width: ${({ theme }) => theme.content.CONTENT_WIDTH};
    `
  };
`;

const Headline = styled(SectionHeadline)`
  margin: 2em 0;
    

  @media all and (min-width: 900px) {
    h2 {
      font-size: 4em;
    }
  }
`;

const BasicHeadline = styled.h2`
  color: ${({ theme }) => theme.colors.Primary};
  margin: 1em 0;
  width: 95%;
  text-align: center;
  overflow: hidden;
  span {
    font-family: inherit;
    position: relative;
    &:before,
    &:after {
      content:"";
      width: 1000px;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.Primary};
      position: absolute;
      top: 50%;
    }
    &:before {
      left: -1010px;
      transform: translateY(-50%);
    }
    &:after {
      right: -1010px;
      transform: translateY(-50%);
    }
  }
  @media all and (min-width: 900px) {
    font-size: 5em;
  }
  @media all and (min-width: 1040px) {
    width: 100%;
  }
`;

const EntryList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1em;
  max-width: 700px;
  li {
    margin: 1em 0;
  }
  @media all and (min-width: 600px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    ${( { isOnCarryOnPage  } ) => 
      isOnCarryOnPage &&
        css`
          li {
            width: 45%;
          }  `
    };
    ${( { isOnBlog } ) =>
    isOnBlog &&
      css`
        justify-content: space-around;
      `
    };
  }
  @media all and (min-width: 900px) {
    max-width: 900px;
    ${( { isOnBlog } ) =>
    isOnBlog &&
      css`
        justify-content: space-between;
      `
    };
    li {
      width: 31%;
    }
  }
  @media all and (min-width: 1100px) {
    max-width: 1100px;
  }
`;

const CtaLink = styled(Link)`
  font-family: 'Bebas Neue', serif;
  font-size: 2.4em;
  color: ${({ theme }) => theme.colors.Primary};
  border: 2px solid ${({ theme }) => theme.colors.Primary};
  align-self: center;
  padding: 0.2em 1em;
  padding-top: 0.35em;
  margin-top: 0.5em;
  line-height: 1;
  transition: all 0.3s ease-in-out;
  &:hover { 
    background-color: ${({ theme }) => theme.colors.Primary};
    color: white;
  }
`;