import React from 'react';

import styled, { css } from 'styled-components';


const SubmitStory = ({

}) => {

  return (
    <Container>
      <img src="/icons/pin-flag.svg" alt="Icon of a golf pin"/>
      <h4>To submit your golf story, tee up an email with your story and a photo!</h4>
      <a href="mailto:carryon@linksbrewing.com?subject=Carry%20On%20Submission" target="_blank" rel="noopener noreferrer">Email Us</a>
    </Container>
  )
};

SubmitStory.propTypes = {

};

export default SubmitStory;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3em 0;
  padding: 0 1em;
  padding-bottom: 4em;
  img {
    width: 30px;
    height: auto;
    margin: 3em 0;
  }
  h4 {
    color: ${({ theme }) => theme.colors.Primary};
    font-size: 2em;
    text-align: center;
    font-family: 'Averia Serif Libre', serif;
  }

  a {
    font-family: 'Bebas Neue', serif;
    font-size: 2.4em;
    color: ${({ theme }) => theme.colors.Primary};
    border: 2px solid ${({ theme }) => theme.colors.Primary};
    align-self: center;
    padding: 0.2em 1em;
    margin-top: 1em;
    line-height: 1;
    &:hover { 
      background-color: ${({ theme }) => theme.colors.Primary};
      color: white;
    }
  }

  @media all and (min-width: 700px) {
    h4 {
      font-size: 2.4em;
    }
  }
`;